<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <h5 class="font-weight-light text-uppercase mb-4">
      {{ $t("quickStartPage.title") }}
    </h5>
    <p>{{ $t("quickStartPage.firstText") }}</p>

    <div class="row justify-content-center">
      <div class="col-md-9">
        <div class="shadow bg-light border-0 p-4 mt-4">
          <div class="pb-2 pt-2 px-3">
            <h5 class="pl-3 font-weight-bold">
              {{ $t("quickStartPage.newConfiguration") }}
            </h5>
            <hr class="mb-0" />
            <b-form-tags
              v-model="selected_options"
              size="lg"
              add-on-change
              no-outer-focus
              class="my-2 form-control-no-shadow bg-light px-0"
            >
              <template
                v-slot="{
                  tags,
                  inputAttrs,
                  inputHandlers,
                  disabled,
                  removeTag,
                }"
              >
                <ul
                  v-if="tags.length > 0"
                  class="list-inline d-inline-block mb-2"
                >
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="removeTag(tag)"
                      :title="tag"
                      :disabled="disabled"
                      variant="primary"
                      >{{ tag }}</b-form-tag
                    >
                  </li>
                </ul>
                <b-form-select
                  v-bind="inputAttrs"
                  v-on="inputHandlers"
                  :disabled="disabled || options.length === 0"
                  :options="options"
                >
                  <template v-slot:first>
                    <option disabled value="">
                      {{ $t("orderStatus.selectOption") }}
                    </option>
                  </template>
                </b-form-select>
              </template>
            </b-form-tags>
            <div class="text-right">
              <button
                class="button-success"
                :disabled="!!optionsToAddNewConfiguration.length"
                v-on:click="addNewConfiguration"
              >
                {{ $t("quickStartPage.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-md-9 mb-4">
        <div v-if="loading">
          {{ $t("loading") }}
        </div>
        <div v-else>
          <div v-if="optionsToAddNewConfiguration.length">
            <b-card class="my-1">
              <b-card-text>
                <div class="text-right">
                  <span class="fas fa-times" v-on:click="close"> </span>
                </div>
                <div class="d-flex mb-3">
                  <b-form-checkbox-group
                    v-model="value"
                    :options="optionss"
                    :state="state"
                    name="checkbox-validation"
                  >
                    <b-form-invalid-feedback :state="state">{{
                      $t("quickStartPage.pleaseSelectOne")
                    }}</b-form-invalid-feedback>
                    <b-form-valid-feedback :state="state">{{
                      $t("quickStartPage.done")
                    }}</b-form-valid-feedback>
                  </b-form-checkbox-group>
                </div>
                <b-row
                  v-for="(option, index) in optionsToAddNewConfiguration"
                  :key="index"
                >
                  <b-col>
                    {{ optionKey[option] }}
                  </b-col>
                  <b-col>
                    <div v-if="selectOrInput(option) == 'select'">
                      <b-form-select
                        :options="selectOptions(option)"
                        v-model="action[option]"
                      >
                        <template v-slot:first>
                          <option disabled value="null">
                            {{ $t("orderStatus.selectOption") }}
                          </option>
                        </template>
                      </b-form-select>
                    </div>
                    <div v-else-if="selectOrInput(option) == 'field'">
                      <b-form-input
                        placeholder="Enter here"
                        v-model="action[option]"
                      >
                      </b-form-input>
                    </div>
                  </b-col>
                </b-row>
                <div class="text-right">
                  <button class="button-success mt-2" v-on:click="save">
                    {{ $t("save") }}
                  </button>
                </div>
              </b-card-text>
            </b-card>
          </div>

          <div v-if="quickStarts.length">
            <div
              v-for="(quickstart, index) in quickStarts"
              :key="index"
              class="p-4 m-4 shadow"
            >
              <b-card-body class="px-3 py-0">
                <div class="pb-2 pt-2">
                  <h5 class="pl-3">
                    {{ $t("quickStartPage.configurationAppliedTo") }}:
                    <template>
                      <b>
                        {{ getType(quickstart.action_type) }}
                      </b>
                    </template>
                    <span
                      class="fas fa-times text-muted float-right"
                      data-toggle="modal"
                      data-target="#confirmDelete"
                      @click="pathToDelete(quickstart)"
                    ></span>
                  </h5>
                  <hr class="mb-0" />
                </div>

                <div
                  v-if="
                    Object.keys(quickStartsData(quickstart)).length === 0 &&
                    editPosition != quickstart.id
                  "
                  class="mt-2"
                >
                  {{ $t("quickStartPage.noConfigApplied") }}
                </div>

                <div
                  v-for="(value, key) in quickStartsData(quickstart)"
                  :key="key"
                  class="row py-1"
                >
                  <b-col class="font-weight-bold">{{ optionKey[key] }}</b-col>

                  <b-col v-if="editPosition != quickstart.id">
                    <span v-if="key == 'insurance'">
                      {{ value == "true" ? $t("yes") : $t("no") }}
                    </span>
                    <span v-else-if="key == 'origin_country'">
                      {{ getCountryName(value) }}
                    </span>
                    <span v-else-if="key == 'contract'">
                      {{ getContractName(value) }}
                    </span>
                    <span v-else-if="key == 'sales_channel'">
                      {{ getSalesChannelName(value) }}
                    </span>
                    <span v-else>
                      {{ value }}
                    </span>
                  </b-col>

                  <b-col v-else>
                    <div v-if="selectOrInput(key) == 'select'">
                      <b-form-select
                        :options="selectOptions(key)"
                        v-model="actionEdit[key]"
                        v-on:change="modification = true"
                      >
                        <template v-slot:first>
                          <option disabled value="null">
                            {{ $t("orderStatus.selectOption") }}
                          </option>
                        </template>
                      </b-form-select>
                    </div>
                    <div v-else-if="selectOrInput(key) == 'field'">
                      <b-form-input
                        placeholder="Enter here"
                        v-model="actionEdit[key]"
                        v-on:change="modification = true"
                      ></b-form-input>
                    </div>
                  </b-col>
                  <i
                    class="fas fa-times mt-2"
                    v-on:click="deleteFromQuickStarts(index, key)"
                    v-if="editPosition == quickstart.id"
                    style="font-size: 15px"
                  ></i>
                </div>

                <div
                  v-if="
                    editPosition == quickstart.id &&
                    optionsUpdateConfig.length > 0
                  "
                >
                  <b-row
                    v-for="(option, index) in optionsUpdateConfig"
                    :key="index"
                  >
                    <b-col class="font-weight-bold">
                      {{ optionKey[option] }}
                    </b-col>
                    <b-col>
                      <div v-if="selectOrInput(option) == 'select'">
                        <b-form-select
                          :options="selectOptions(option)"
                          v-model="action[option]"
                          v-on:change="modification = true"
                        >
                          <template v-slot:first>
                            <option disabled value="null">
                              {{ $t("orderStatus.selectOption") }}
                            </option>
                          </template>
                        </b-form-select>
                      </div>
                      <div v-else-if="selectOrInput(option) == 'field'">
                        <b-form-input
                          v-on:change="modification = true"
                          placeholder="Enter here"
                          v-model="action[option]"
                        >
                        </b-form-input>
                      </div>
                    </b-col>
                    <i
                      class="fas fa-times mt-2"
                      v-on:click="deleteFromNewAddedOptionsWhenUpdating(index)"
                      v-if="editPosition == quickstart.id"
                      style="font-size: 15px"
                    ></i>
                  </b-row>
                </div>
                <div class="text-right">
                  <i
                    class="fas fa-plus mt-2"
                    v-if="editPosition == quickstart.id && !showTag"
                    v-on:click="addNewOption()"
                  ></i>
                </div>
                <b-form-tags
                  v-model="selected_options_update"
                  size="lg"
                  add-on-change
                  no-outer-focus
                  class="my-2 form-control-no-shadow px-0"
                  v-if="editPosition == quickstart.id && showTag"
                >
                  <template
                    v-slot="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      disabled,
                      removeTag,
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          @remove="removeTag(tag)"
                          :title="tag"
                          :disabled="disabled"
                          variant="primary"
                          >{{ tag }}</b-form-tag
                        >
                      </li>
                    </ul>
                    <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || options.length === 0"
                      :options="options"
                    >
                      <template v-slot:first>
                        <option disabled value="">
                          {{ $t("orderStatus.selectOption") }}
                        </option>
                      </template>
                    </b-form-select>
                  </template>
                </b-form-tags>
                <div class="text-right">
                  <button
                    class="button-success"
                    v-if="editPosition == quickstart.id && showTag"
                    :disabled="!!optionsUpdateConfig.length"
                    v-on:click="addNewOptionUpdate()"
                  >
                    {{ $t("quickStartPage.add") }}
                  </button>
                </div>
                <div class="text-right">
                  <button
                    class="mt-2 mr-2 button-light"
                    v-on:click="cancel(index)"
                    v-if="editPosition == quickstart.id"
                  >
                    {{ $t("cancel") }}
                  </button>
                  <button
                    class="mt-2 button-primary"
                    v-on:click="toEdit(index)"
                    v-if="editPosition != quickstart.id"
                  >
                    {{ $t("quickStartPage.modify") }}
                  </button>
                  <button
                    class="mt-2 button-success"
                    data-toggle="modal"
                    data-target="#confirmSave"
                    v-if="modification && editPosition == quickstart.id"
                  >
                    {{ $t("save") }}
                  </button>
                </div>
              </b-card-body>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="confirmSave"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Update"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="Update">{{ $t("update") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("quickStartPage.confirmChanges") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="mr-2 btn btn-secondary"
              id="confirmSaveClose"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="saveEdition"
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="confirmDelete"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Delete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="Update">
              {{ $t("quickStartPage.deleteConfiguration") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("quickStartPage.title") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="mr-2 btn btn-secondary"
              id="confirmDeleteClose"
              data-dismiss="modal"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="deleteConfiguration"
            >
              {{ $t("delete") }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="unsaved"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Unsaved"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="Unsaved">
              {{ $t("quickStartPage.unsavedData") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            {{ $t("quickStartPage.saveUnsavedOrCancel") }}
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="mr-2 btn btn-primary"
              data-dismiss="modal"
            >
              {{ $t("quickStartPage.ok") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuickstartService from "../../services/QuickStartService";
import CountryService from "../../services/CountryService";
import Category from "../../assets/data/category.js";
import ContractService from "../../services/ContractService";
import SalesChannelGroupService from "../../services/SalesChannelGroupService";
import $ from "jquery";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const countryService = new CountryService();
const quickStartService = new QuickstartService();
const contractService = new ContractService();
const salesChannelService = new SalesChannelGroupService();

export default {
  components: { LoadingPage },
  data() {
    return {
      loaded: true,
      optionsContracts: [],
      quickstartEdit: {},
      editPosition: false,
      loadingQuickstarts: false,
      checked_order: null,
      checked_product: null,
      checked_input: null,
      countries: [],
      optionsToAddNewConfiguration: [],
      selected_options: [],
      selected_options_update: [],
      quickStarts: [],
      loading: true,
      action: {
        hs_code: null,
        currency: null,
        contract: null,
        incoterm: null,
        insurance: null,
        origin_country: null,
        category: null,
        sales_channel: null,
        freight_value: null,
        handling_time: null,
      },
      actionEdit: {
        hs_code: null,
        currency: null,
        contract: null,
        incoterm: null,
        insurance: null,
        origin_country: null,
        category: null,
        sales_channel: null,
        freight_value: null,
        handling_time: null,
      },
      tempActionEdit: {
        hs_code: null,
        currency: null,
        contract: null,
        incoterm: null,
        insurance: null,
        origin_country: null,
        category: null,
        sales_channel: null,
        freight_value: null,
        handling_time: null,
      },
      tempQuickStart: null,
      /* option_insurance: [
          {value: "true", text: 'Yes'},
          {value: "false", text: 'No'},
        ],
        option_incoterm: [
          {value: 'DDP', text: 'Sender - DDP'},
          {value: 'DDU', text: 'Receiver - DDU'},
        ],
        option_currency: [
          {value: 'USD', text: 'USD'},
          {value: 'R$', text: 'BRL'},
        ],
        option_contract: [
          {value: 'correios', text: 'Correios'},
          {value: 'others', text: 'Others'},
        ],
        optionKey: {
          hs_code: 'HS Code',
          currency: 'Currency',
          contract: 'Contract',
          incoterm: 'Incoterm',
          insurance: 'Insurance',
          origin_country: 'Origin of the products',
          category: 'Product category',
          sales_channel: 'Sales channel',
          freight_value: 'Freight Value',
          handling_time: 'Handling Time',
        }, */
      value: [],
      valuesUpdate: [],
      /* optionss: [
          { text: 'Order create', value: 'order' },
          { text: 'Order import', value: 'input' },
          { text: 'Product', value: 'product' },
          { text: 'Quotes', value: 'quotes' }
        ], */
      optionsUpdate: [],
      optionsUpdateConfig: [],
      showTag: false,
      modification: false,
      quickstartIdToDelete: null,
      optionsSalesChannels: [],
    };
  },
  mounted() {
    contractService.getContracts().then((response) => {
      response.data.data.forEach((contract) => {
        if (contract.id) {
          this.optionsContracts.push({
            text: contract.name,
            value: contract.id,
          });
        }
      });
      this.loaded = false;
    });

    countryService.getCountries().then((response) => {
      this.countries = response.data.data;
    });

    salesChannelService.getSalesChannels().then((response) => {
      response.data.data.forEach((sales) => {
        if (sales.id) {
          this.optionsSalesChannels.push({
            text: sales.name,
            value: sales.id,
          });
        }
      });
    });

    this.getQuicks();
  },
  methods: {
    deleteFromNewAddedOptionsWhenUpdating(index) {
      this.optionsUpdateConfig.splice(index, 1);
    },

    deleteFromQuickStarts(index, key) {
      this.quickStarts[index][key] = null;
      this.actionEdit[key] = null;

      this.modification = true;
    },

    addNewOptionUpdate() {
      this.selected_options_update.forEach((option) => {
        this.optionsUpdateConfig.push(option);
      });
      this.selected_options_update = [];
      this.showTag = false;
      //actionEdit
    },

    addNewOption() {
      this.modification = true;
      this.showTag = true;
    },

    getCountryName(value) {
      const found = this.countries.find((country) => {
        return country.alpha2_code == value;
      });
      return found.name;
    },

    getContractName(value) {
      return this.optionsContracts.find((contract) => contract.value == value)
        .text;
    },

    getSalesChannelName(value) {
      return this.optionsSalesChannels.find((sales) => sales.value == value)
        .text;
    },

    toEdit(index) {
      if (this.modification) {
        $("#unsaved").modal("show");
        return false;
      }
      this.showTag = false;
      this.selected_options_update = [];
      this.optionsUpdateConfig = [];
      const quickstart = this.quickStarts[index];
      this.tempQuickStart = JSON.parse(JSON.stringify(this.quickStarts[index]));
      if (!quickstart) {
        return false;
      }
      this.optionsUpdate = this.getChosenOptions(quickstart.action_type);
      this.actionEdit = {
        hs_code: quickstart.hs_code,
        currency: quickstart.currency,
        contract: quickstart.contract,
        incoterm: quickstart.incoterm,
        insurance: quickstart.insurance,
        origin_country: quickstart.origin_country,
        category: quickstart.category,
        sales_channel: quickstart.sales_channel,
        freight_value: quickstart.freight_value,
        handling_time: quickstart.handling_time,
      };
      this.tempActionEdit = JSON.parse(JSON.stringify(this.actionEdit));

      this.editPosition = quickstart.id;
      this.quickstartEdit = quickstart;
    },

    saveEdition() {
      for (let i = 0; i < this.optionsUpdateConfig.length; i++) {
        this.actionEdit[this.optionsUpdateConfig[i]] =
          this.action[this.optionsUpdateConfig[i]];
      }

      if (this.actionEdit.hs_code && this.actionEdit.hs_code.length < 6) {
        this.$helpers.toast("HS Code Wrong", "danger");
        return false;
      }
      if (this.actionEdit.freight_value && this.actionEdit.freight_value < 0) {
        this.$helpers.toast("Freight value cannot be negative", "danger");
        return false;
      }
      if (this.actionEdit.handling_time && this.actionEdit.handling_time < 0) {
        this.$helpers.toast("Handling Time cannot be negative", "danger");
        return false;
      }
      let actionList = "";
      for (let i = 0; i < this.valuesUpdate.length; i++) {
        if (i + 1 != this.valuesUpdate.length) {
          actionList = actionList.concat(this.valuesUpdate[i], ",");
        } else {
          actionList = actionList.concat(this.valuesUpdate[i]);
        }
      }
      const data = {
        action_type: actionList,
        hs_code: this.actionEdit.hs_code,
        currency: this.actionEdit.currency,
        contract: this.actionEdit.contract,
        incoterm: this.actionEdit.incoterm,
        insurance: this.actionEdit.insurance,
        origin_country: this.actionEdit.origin_country,
        category: this.actionEdit.category,
        sales_channel: this.actionEdit.sales_channel,
        freight_value: this.actionEdit.freight_value,
        handling_time: this.actionEdit.handling_time,
      };
      quickStartService
        .updateQuick(this.quickstartEdit.id, data)
        .then(() => {
          document.getElementById("confirmSaveClose").click();
          //$('#confirmSave').modal('hide');
          this.optionsUpdateConfig = [];
          this.showTag = false;
          this.editPosition = false;
          this.quickstartEdit = {};
          this.optionsToAddNewConfiguration = [];
          this.actionEdit = {
            hs_code: null,
            currency: null,
            contract: null,
            incoterm: null,
            insurance: null,
            origin_country: null,
            category: null,
            sales_channel: null,
            freight_value: null,
            handling_time: null,
          };
          this.checked_product = null;
          this.checked_order = null;
          this.checked_input = null;
          this.value = [];
          this.loadingQuickstarts = true;
          this.getQuicks();
          this.modification = false;
        })
        .catch((error) => {
          this.$helpers.toast(
            error.message || error.msg || "Request failed",
            "danger"
          );
        });
    },

    getType(type) {
      type = type.split(",");
      let typeMessage = "";
      let selected = [];
      if (type.indexOf("order") != -1) {
        selected.push("ORDER CREATE");
      }
      if (type.indexOf("input") != -1) {
        selected.push("ORDER  IMPORT");
      }
      if (type.indexOf("product") != -1) {
        selected.push("PRODUCT");
      }
      if (type.indexOf("quotes") != -1) {
        selected.push("QUOTES");
      }
      if (selected.length == 0) {
        typeMessage = "";
      } else if (selected.length == 1) {
        typeMessage = selected[0];
      } else {
        let lastSelected = selected.pop();
        typeMessage = selected.join(", ") + " AND " + lastSelected;
      }
      return typeMessage;
    },

    cancel(index) {
      this.editPosition = false;
      this.quickStarts[index] = JSON.parse(JSON.stringify(this.tempQuickStart));
      this.actionEdit = JSON.parse(JSON.stringify(this.tempActionEdit));
      this.modification = false;
    },

    getChosenOptions(str) {
      str = str.split(",");
      let old_options = [];
      let other_options = [];
      for (let i = 0; i < this.optionss.length; i++) {
        if (str.includes(this.optionss[i].value)) {
          old_options.push(this.optionss[i]);
        } else {
          other_options.push(this.optionss[i]);
        }
      }
      this.valuesUpdate = str;
      return old_options.concat(other_options);
    },

    quickStartsData(data) {
      const omit = [
        "created_at",
        "updated_at",
        "id",
        "seller_id",
        "action_type",
        "box",
        "packing_time",
      ];
      const quick = {};
      for (const [key, value] of Object.entries(data)) {
        if (!omit.includes(key) && value) {
          quick[key] = value;
        }
      }
      return quick;
    },

    getQuicks() {
      quickStartService
        .getQuicks("index")
        .then((response) => {
          const data = response.data.data;
          this.quickStarts = data;
        })
        .then(() => {
          this.loading = false;
          this.loadingQuickstarts = false;
        });
    },

    deleteConfiguration() {
      this.loadingQuickstarts = true;
      quickStartService
        .deleteQuick(this.quickstartIdToDelete)
        .then(() => {
          document.getElementById("confirmDeleteClose").click();
          //$('#confirmDelete').modal('hide');
          this.quickStarts = [];
          this.getQuicks();
          this.quickstartIdToDelete = null;
        })
        .catch((error) => {
          this.$helpers.toast(
            error.message || error.msg || "Request failed",
            "danger"
          );
          this.quickstartIdToDelete = null;
        });
    },

    close() {
      this.optionsToAddNewConfiguration = [];
      this.value = [];
      this.showTag = false;
    },

    addNewConfiguration() {
      this.selected_options.forEach((option) => {
        this.optionsToAddNewConfiguration.push(option);
      });
      this.selected_options = [];
    },

    save() {
      if (this.value < 1) {
        return false;
      }

      let type = "";
      if (this.value.includes("product")) {
        type += "product";
      }
      if (this.value.includes("order")) {
        type += type == "" ? "order" : ",order";
      }
      if (this.value.includes("input")) {
        type += type == "" ? "input" : ",input";
      }
      if (this.value.includes("quotes")) {
        type += type == "" ? "quotes" : ",quotes";
      }
      if (this.action.hs_code && this.action.hs_code.length < 6) {
        this.$helpers.toast("HS Code Wrong", "danger");
        return false;
      }
      if (this.actionEdit.freight_value && this.actionEdit.freight_value < 0) {
        this.$helpers.toast("Freight value cannot be negative", "danger");
        return false;
      }
      if (this.actionEdit.handling_time && this.actionEdit.handling_time < 0) {
        this.$helpers.toast("Handling Time cannot be negative", "danger");
        return false;
      }
      let existingQuickStart = false;
      let itemRepeated = [];
      this.quickStarts.forEach((quick) => {
        let existingType = false;

        if (
          /*this.checked_product*/ this.value["product"] &&
          quick.action_type.split(",").includes("product")
        ) {
          existingType = true;
        }

        if (
          /*this.checked_order*/ this.value["order"] &&
          quick.action_type.split(",").includes("order")
        ) {
          existingType = true;
        }

        if (
          /*this.checked_input*/ this.value["input"] &&
          quick.action_type.split(",").includes("input")
        ) {
          existingType = true;
        }
        if (
          /*this.checked_input*/ this.value["quotes"] &&
          quick.action_type.split(",").includes("quotes")
        ) {
          existingType = true;
        }

        if (existingType) {
          if (
            (quick.hs_code && this.action.hs_code) ||
            (quick.currency && this.action.currency) ||
            (quick.origin_country && this.action.origin_country) ||
            (quick.incoterm && this.action.incoterm) ||
            (quick.contract && this.action.contract) ||
            (quick.insurance && this.action.insurance) ||
            (quick.sales_channel && this.action.sales_channel) ||
            (quick.category && this.action.category) ||
            (quick.freight_value && this.action.freight_value) ||
            (quick.handling_time && this.action.handling_time)
          ) {
            quick.hs_code &&
              this.action.hs_code &&
              itemRepeated.push("HS Code");
            quick.currency &&
              this.action.currency &&
              itemRepeated.push("Currency");
            quick.origin_country &&
              this.action.origin_country &&
              itemRepeated.push("Origin of the products");
            quick.incoterm &&
              this.action.incoterm &&
              itemRepeated.push("Incoterm");
            quick.contract &&
              this.action.contract &&
              itemRepeated.push("Contract");
            quick.insurance &&
              this.action.insurance &&
              itemRepeated.push("Insurance");
            quick.sales_channel &&
              this.action.sales_channel &&
              itemRepeated.push("Sales channel");
            quick.category &&
              this.action.category &&
              itemRepeated.push("Category");
            quick.freight_value &&
              this.action.freight_value &&
              itemRepeated.push("Freight Value");
            quick.handling_time &&
              this.action.handling_time &&
              itemRepeated.push("Handling Time");
            existingQuickStart = true;
          }
        }
      });

      if (existingQuickStart) {
        this.$helpers.toast(
          "Item repeated in some configuration: " + itemRepeated.join(", "),
          "danger"
        );
        return false;
      }

      const data = {
        action_type: type,
        hs_code: this.action.hs_code,
        currency: this.action.currency,
        contract: this.action.contract,
        incoterm: this.action.incoterm,
        insurance: this.action.insurance,
        origin_country: this.action.origin_country,
        category: this.action.category,
        sales_channel: this.action.sales_channel,
        freight_value: this.action.freight_value,
        handling_time: this.action.handling_time,
      };

      quickStartService
        .createQuick(data)
        .then(() => {
          this.optionsToAddNewConfiguration = [];
          this.action = {
            hs_code: null,
            currency: null,
            contract: null,
            incoterm: null,
            insurance: null,
            origin_country: null,
            category: null,
            sales_channel: null,
            freight_value: null,
            handling_time: null,
          };
          this.checked_product = null;
          this.checked_order = null;
          this.checked_input = null;
          this.value = [];
          this.loadingQuickstarts = true;
          this.getQuicks();
        })
        .catch((erro) => {
          this.$helpers.toast(
            erro.message || erro.msg || "Request failed",
            "danger"
          );
        });
    },

    selectOptions(option) {
      if (option == "currency") {
        return this.option_currency;
      } else if (option == "contract") {
        return this.optionsContracts;
      } else if (option == "incoterm") {
        return this.option_incoterm;
      } else if (option == "insurance") {
        return this.option_insurance;
      } else if (option == "origin_country") {
        let countries = [];
        this.countries.forEach((country) => {
          countries.push({ value: country.alpha2_code, text: country.name });
        });
        return countries;
      } else if (option == "category") {
        return Category;
      } else if (option == "sales_channel") {
        return this.optionsSalesChannels;
      } else return [];
    },

    selectOrInput(type) {
      if (type == "contract") {
        return "select";
      } else if (type == "currency") {
        return "select";
      } else if (type == "hs_code") {
        return "field";
      } else if (type == "incoterm") {
        return "select";
      } else if (type == "insurance") {
        return "select";
      } else if (type == "origin_country") {
        return "select";
      } else if (type == "category") {
        return "select";
      } else if (type == "sales_channel") {
        return "select";
      } else if (type == "freight_value") {
        return "field";
      } else if (type == "handling_time") {
        return "field";
      } else return false;
    },
    pathToDelete(quickstart) {
      this.quickstartIdToDelete = quickstart.id;
    },
  },
  computed: {
    state() {
      return this.value.length >= 1;
    },
    stateUpdate() {
      return this.valuesUpdate.length >= 1;
    },
    options() {
      let options = [
        { value: "contract", text: "Contract" },
        { value: "currency", text: "Currency" },
        { value: "hs_code", text: "HS Code" },
        { value: "incoterm", text: "Incoterm(Taxes & Duties)" },
        { value: "insurance", text: "Insurance" },
        { value: "origin_country", text: "Origin of the products" },
        { value: "category", text: "Product category" },
        { value: "sales_channel", text: "Sales Channel" },
        { value: "freight_value", text: "Freight Value" },
        { value: "handling_time", text: "Handling Time" },
      ];

      let options_avaiable = [];

      options.forEach((option) => {
        const found = this.selected_options.find((opt) => {
          return opt == option.value;
        });
        if (!found) {
          options_avaiable.push(option);
        }
      });

      return options_avaiable;
    },
    option_insurance() {
      return [
        { value: "true", text: this.$t("yes") },
        { value: "false", text: this.$t("no") },
      ];
    },
    option_incoterm() {
      return [
        { value: "DDP", text: this.$t("sender") },
        { value: "DDU", text: this.$t("receiver") },
      ];
    },
    option_currency() {
      return [
        { value: "USD", text: "USD" },
        { value: "R$", text: "BRL" },
      ];
    },
    option_contract() {
      return [
        { value: "correios", text: this.$t("correios") },
        { value: "others", text: this.$t("others") },
      ];
    },
    optionKey() {
      return {
        hs_code: this.$t("hsCode"),
        currency: this.$t("currency"),
        contract: this.$t("contract"),
        incoterm: this.$t("ordersListPage.incoterm"),
        insurance: this.$t("insurance"),
        origin_country: this.$t("originCountry"),
        category: this.$t("quickStartPage.productCategory"),
        sales_channel: this.$t("salesChannel"),
        freight_value: this.$t("ordersListPage.freightCost"),
        handling_time: this.$t("handlingTime"),
      };
    },
    optionss() {
      return [
        { value: "order", text: this.$t("orderCreate") },
        { value: "input", text: this.$t("orderImport") },
        { value: "product", text: this.$t("product") },
        { value: "quotes", text: this.$t("quotes") },
      ];
    },
  },
};
</script>

<style lang="scss">
/* 


    #quickstart-save-button{
        padding: 5px 15px;
        background-color: var(--green);
        color: var(--lightGray);
        border: 1px solid var(--green);
        transition: 500ms;
    }

    #quickstart-save-button:hover{
        background-color:var(--white);
        color: var(--lightGreen);
        border: 1px solid var(--lightGreen);
    }

    .quickstart-cancel-button{
        padding: 5px 15px;
        background-color:var(--shadow);
        color: var(--lightGray);
        border: 1px solid var(--shadow);
        transition: 500ms;
    }

    .quickstart-cancel-button:hover{
        background-color:var(--white);
        color: var(--shadow);
        border: 1px solid var(--shadow);
    }

    #quickstart-save-button:disabled,
    #quickstart-save-button[disabled]{
      border: 1px solid var(--secondary);
      background-color: var(--secondary);
      color: var(--lightGray);
      opacity:0.7;
    }
 */
</style>
