import HttpService from "./HttpService";

export default class SalesChannelGroupService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get groups
   *
   *
   * @returns {Promise}
   */
  getGroups() {
    return this.httpService.get("/v1/sales-channels-groups");
  }

  getSalesChannels() {
    return this.httpService.get("/v1/sales_channels");
  }

  getSalesChannelsWhithoutGroup() {
    return this.httpService.get("/v1/sales-channels/without/group");
  }

  getGroupInfos(name) {
    return this.httpService.get("/v1/sales-channels-groups/group?name=" + name);
  }

  addSalesChannel(parameters) {
    return this.httpService.post("/v1/sales-channels-groups/group", parameters);
  }

  createGroup(parameters) {
    return this.httpService.post("/v1/sales-channels-groups", parameters);
  }

  changeConfig(parameters) {
    return this.httpService.post(
      "v1/sales-channels-groups/group/configs",
      parameters
    );
  }

  deleteSalesChannel(parameters) {
    var group = parameters["name"];
    var sales_list =
      "&sales_channels[]=" +
      parameters["sales_channels"].join("&sales_channels[]=");
    return this.httpService.delete(
      "/v1/sales-channels-groups/group?name=" + group + sales_list
    );
  }

  deleteGroup(parameters) {
    var group = parameters["name"];
    return this.httpService.delete("/v1/sales-channels-groups?name=" + group);
  }

  getConfigs(option) {
    var options_list = "?option[]=" + option.join("&option[]=");
    return this.httpService.get(
      "/v1/sales-channels-groups/group/configs" + options_list
    );
  }
}
